import { Component } from "react";

import GeneralTags from "./GeneralTags";

class PageSEO extends Component {
  render() {
    const { pagePath, title, description, image } = this.props;

    return (
      <div>
        <GeneralTags
          pagePath={pagePath}
          title={title}
          description={description}
          image={image}
        />
      </div>
    );
  }
}

export default PageSEO;
